<template>
    <div class="footer2">
        <p class="copyright">
            广州市番禺区钟村街汉溪村（汉溪商业中心）泽溪街9号1205<br>
            Copyright © 2008-2022 广州犇牛信息产业有限公司. 保留所有权利。 <a href="https://beian.miit.gov.cn" target="_blank">粤ICP备2024255360号</a>
        </p>
    </div>
</template>

<script>
    export default {
        props:{
			type:{
				type:String,
				// default:'1'
			},
		},
        data() {
            return {
                height:0
            }
        },
        created(){
            this.height = document.documentElement.clientHeight;
        }
    }
</script>

<style lang="less">
.footer2 {
    // position: fixed;
    // bottom: 0;
    margin-top: 40px !important;
    padding: 10px;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: #fff;
    box-shadow: 4px 0px 4px 0 rgb(0 0 0 / 5%);
}
.copyright {
    margin: 0 auto;
    text-align: center;
}
.copyright a {
    color: #222;
}
</style>