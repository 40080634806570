<template>
    <div>
        <Header></Header>
        <div class="content index_head">
            <div class="index_head_class">
                <ul :style="{marginRight: teacherMenuList.length > 10 ? '-17px' : 0}">
                    <li v-for="(item,index) in teacherMenuList" :key="index" @click="link_page(item.clickUrl,item.name,item.id,item.atMark)">{{item.name}}</li>                     
                </ul>
            </div>
            <div class="index_head_left">
                <el-carousel height="420px" trigger="click">
                    <el-carousel-item v-for="(item,index) in advertList" :key="index" >
                        <img :src="item.advPosPic|| ''" alt="" class="banner_img" @click="link_adv(item.type,item.typeId)">
                    </el-carousel-item>
                </el-carousel>
            </div>
            <div class="index_head_right">
                <div class="right_title">
                    <!-- <div class="spjz"><div class="iconfont icon-huo"></div> 推荐资讯</div> -->
                    <div class="spjz"><div class="iconfont icon-huo"></div> 推荐软件</div>
                    <div @click="$links('/news_list?recommendType=1')" class="title_right spjz aaa mr15 cup" >更多 <div class="iconfont icon-right"></div></div>
                </div>
                <div class="right_item spjz" v-for="(item,index) in InformationList" @click="links_news(item.id,item.inforType)">
                    <div class="item_num">{{index+1}}</div>
                    <div class="item_text chaochu">
                        {{ item.content  }}
                    </div>
                </div>
                <nodata class="mt30" no_data="暂无资讯" v-if="InformationList.length<=0"></nodata>
            </div>
        </div>
        <div class="content index_list" v-if="video_list.length>0">
            <div class="list_item">
                <div class="item_title">
                    <div class="spjz">
                        <div class="iconfont icon-huo"></div> 优质课程
                    </div>
                    <div @click="$links('/commend_screen')" class="title_right spjz">更多 <div class="iconfont icon-right"></div></div>
                </div>
                <videoList :list="video_list"></videoList>
                <!-- <div class="item_row">
                    <div class="row_" v-for="(item,index) in 8" @click="link_detail()">
                        <img src="https://img-bss.csdnimg.cn/20230424170509459.jpg" alt="" class="row_img">
                        <div class="row_text">
                            <div class="row_title caochu">
                                C语言，2022年新录制，c99 c11 c17 c23，29个标准库
                            </div>
                            <div class="row_info">
                                共480节 · 10455学习 · 包东华
                            </div>
                            <div class="row_money">
                                ¥48 <span>¥488</span>
                            </div>
                        </div>
                    </div>
                </div> -->
            </div>
        </div>
        <div class="about_content content">
            <div class="mt40" v-for="item in aboutObj.moduleList" :key='item.id'>
                <div class="about_title ">{{item.title}}</div>
                <div class="about_text" v-html="tran(item.text)"></div>
            </div>
        </div>
        <Homefooter2></Homefooter2>
    </div>
</template>

<script>
    import { mapGetters } from "vuex";
    import { aboutInfo,sourc,sourc2,homeIndex,selectByIdBase2,getByIdBase } from "@/apiUrl/index.js";
    import videoList from '@/components/videoList'
    import Homefooter2 from "@/components/footer2.vue";
    export default {
        components:{
            videoList,
            Homefooter2
        },
        computed: {
            ...mapGetters(["token"]),
        },
        data() {
            return {
                sourc,sourc2,
                goods: [],
				video_list:[], // 精选推荐
				id: '',
				version: true, // true审核版本  false不是审核版本
				project_name: this.$store.state.project_name,
				list:[],
				projectList:[],
				memberPhotoAlbum:{},
				teacherMenuList:[],
				teacherMenuOK:false,
				teacherdots:false,
				memberPhotoAlbumList:[],
				teacherList:[],
				homePageStatus:1,
				projec:[],
				serviceVideoPhotoUrl:'',
				serviceVideoUrl:'',
				advertList:[], // 广告位
				activityList:[], // 活动列表
				tenantSet:{}, // 租户信息
				Teachertype:{}, // 导师类型
				VideoType:{}, // 视频类型
				projectType:{}, // 项目类型
				activityType:{}, // 活动类型
				publicizeType:{}, // 资讯类型
				aboutUsType:{}, // 简介类型
				topicType:'',
				topicSolidList:[],// 固定话题列表
				InformationList:[], // 资讯数据列表
				aboutInfo:{},
                aboutObj:{},
				showInfo:false
            };
        },
        created() {
            
            this.getdata_v3();
            this.getAbout()
        },
        methods: {
            tran(about_us) {
					if (!about_us) return ''
					about_us = about_us.replace(/<img src="/g,
                        '<img style="margin:0 auto;width:300px;height:auto;display:block;margin-bottom:10px;border-radius: 4px;" src="'
                        )
                    .replace(/<li/g, '<li style="overflow: hidden;"');
					return about_us
				},
            getAbout() {
                aboutInfo().then(res => {
                    if(res.code == 200) {
                        this.aboutObj = res.data
                    }
                    
                })
            },
            links_news(id,type) {
                if(type == 1) {
                    this.$links('/news_detail?id=' + id)
                } else if(type == 2) {
                    this.$links('/v_play?id=' + id)
                } else if(type == 3) {
                    this.$links('/news_detail2?id=' + id)
                }
            },
            link_adv(type,id) {
				console.log('id',id)
				if(type == 'teacher') { // 导师类型
					// uni.navigateTo({ url: '/pagesB/home/tutor_detail?id=' + id})
				} else if(type == 'video') { // 视频类型
					selectByIdBase2({id}).then(res => {
							if(res.code == 200) {
								let obj = res.data;
								if(obj.type==1){
									this.$link('/video_detail?id=' + id + '&mode=video&timestamp=' + new Date())
								}else if(obj.type==2){
									if(obj.vipSet==2){
										this.$link('/video_detail2?id=' + id + '&mode=video&timestamp=' + new Date())
									}else{
										this.$link('/video_detail1?id=' + id + '&mode=video&timestamp=' + new Date())
									}
								}
							}
					})
				} else if(type == 'project') { // 商机类型
					// uni.navigateTo({ url: '/pagesA/projectDetail/projectDetail?id=' + id})
				} else if(type == 'activity') { // 活动类型
					// uni.navigateTo({ url: '/pagesB/activity/activityDetail?id=' + id})
				} else if(type == 'publicize') { // 活动类型
					if(id) {
						getByIdBase({id}).then(res => {
							if(res.code == 200) {
								let data = res.data;
								if(data.fileType == 1) {
                                    this.$links('/news_detail?id=' + id)
								} else if(data.fileType == 2) {
									this.$links('/v_play?id=' + id)
								} else if(data.fileType == 3) {
									this.$links('/news_detail2?id=' + id)
								}
							}
							
						})
					} else {
						this.$msg('跳转失败！')
					}
				} 
			},
            link_page(url,name,id,type){
				let urls = '';
				if(type == 'video') { // 视频类型
					urls = '/screen'
				} else if(type == 'teacher') { // 导师类型
					// urls = '/pagesB/home/tutor_list'
				} else if(type == 'publicize') { // 企业宣传
					urls = '/news_list'
				} else if(type == 'project') { // 商机类型
					// urls = '/pages/home/index/project'
				} else if(type == 'activity') { // 活动类型
					// urls = '/pagesB/activity/list'
				} else if(type == 'about_us') { // 企业简介
					// urls = '/pagesA/about_us/index'
					id = '';
				}else{
                    this.$link(url)
					return
				}
				this.$links(urls+'?id=' + id + '&icon_name=' + name)
			},
            getdata_v3(e) {
				var el = this;
				
				homeIndex().then((res) => {
					if(res.code==200){
						
						if(res.data.status) { // 租户可用
							this.advertList = res.data.advertList || []; // 广告
							if((this.$store.state.tenantId=='3cfb027eb4634dfab764403a4cfdf01a' || this.$store.state.tenantId=='271d5662d57148a5812297c5cc50b7d3') &&!this.version){
								this.teacherMenuList =[{
									atMark: "",
									clickUrl: '/pagesA/huiyan/content_list?type=0',
									id: "",
									imgUrl: el.sourc2+'mini/icon/huiyanyuzhou/01.png',
									name: "个股",
								},{
									atMark: "",
									clickUrl: '/pagesA/huiyan/content_list?type=1',
									id: "",
									imgUrl: el.sourc2+'mini/icon/huiyanyuzhou/02.png',
									name: "指数",
								},{
									atMark: "",
									clickUrl: '/pagesA/huiyan/content_list?type=2',
									id: "",
									imgUrl: el.sourc2+'mini/icon/huiyanyuzhou/03.png',
									name: "ETF",
								},{
									atMark: "",
									clickUrl: '/pagesA/huiyan/strategy',
									id: "",
									imgUrl: el.sourc2+'mini/icon/huiyanyuzhou/04.png',
									name: "每日策略",
								}]
							}else{
								this.teacherMenuList =[]
							}
							this.teacherMenuList = this.teacherMenuList.concat(res.data.classList || []); // 分类
							
							this.teacherMenuOK =  this.teacherMenuList.length>5; 
							this.teacherdots =  this.teacherMenuList.length>10; 
							this.video_list = res.data.pushVideoList || []; // 推荐视频列表
							this.tenantSet = res.data.tenantSet; // 租户信息
							this.teacherList = res.data.teacherList || []; // 名师推荐
							let list = res.data.projectList || []; // 商机推荐
							list.forEach(item => {
								item.customLabel1 = item.customLabel ?  item.customLabel.split(',') : []
							})
							this.projectList = list;
							this.project_name = res.data.tenantSet.miniApps;
							this.activityList = res.data.activityList || []; // 活动推荐
							let InformationList = res.data.publicizeList || []; //资讯推荐
							InformationList.forEach((v,i)=>{1
								if(v.fileType==1&&v.urls){
									InformationList[i].urls = v.urls.split(',')
								}
							})
							this.InformationList = InformationList;
							this.$store.commit('setTenant',this.tenantSet);
							this.$store.commit('setShare_text',this.tenantSet.shareDescription || '');
							this.$store.commit('setProject_name',this.tenantSet.miniApps || '');
							this.$store.commit('setApp_share_img',this.tenantSet.sharePhoto || '');
							this.$store.commit('setApplicationTypeList',res.data.applicationTypeList || []);
							this.$store.commit('setTopicType',res.data.topicType || '');
							this.topicType = res.data.topicType || '';
							if(this.topicType == 'solid') {
								this.getTopicSolid();
							} 
							if(res.data.applicationTypeList.length ) {
								res.data.applicationTypeList.forEach((item) => {
									if(item.typeMark == 'teacher') {
										this.Teachertype = item
									} else if(item.typeMark == 'video') {
										this.VideoType = item
									} else if(item.typeMark == 'project') {
										this.projectType = item
									} else if(item.typeMark == 'activity') {
										this.activityType = item
									}else if(item.typeMark == 'publicize') {
										this.publicizeType = item 
									} else if(item.typeMark == 'about_us') {
										this.aboutUsType = item 
										
									} 

								})
							}
							setTimeout(() => {
								this.showInfo = true;
							},200)
							
						}
					}else{
						this.$msg(res.message);
					}
				})
			},
            // 详情
            link_detail() {
                this.$links('/video_detail');
            }
        },
    };
</script>
<style lang="less" scoped>
    .index_head{
        overflow: hidden;
        margin-top: 24px;
    }
    .index_head_left{
        width: calc(1280px - 390px - 180px);
        float: left;
        height: 420px;
        border-radius: 6px;
        overflow: hidden;
    }
    .index_head_right{
        width: 370px;
        height: 420px;
        float: right;
        border-radius: 6px;
        overflow: hidden;
        background-color: #fff;
    }
    .banner_img{
        width: 100%;
        height: 100%;
    }
    .right_title{
        height: 56px;
        background-color: var(--color);
        padding-left: 16px;
        box-sizing: border-box;
        font-size: 16px;
        color: #fff;
        font-weight: 700;
        display: flex;
        justify-content: space-between;
        .icon-huo{
            font-size: 20px;
            margin-right: 6px;
        }
    }
    .right_item{
        margin-top: 15px;
        margin-bottom: 15px;
        padding-left: 16px;
        padding-right: 16px;
        cursor: pointer;
        .item_num{
            font-size: 16px;
            color: var(--font-color);
        }
        .item_text{
            padding-left: 16px;
            font-size: 16px;
            color: var(--font-color);
        }
    }
    .right_item:hover{
        .item_num,.item_text{
            color: var(--color);
        }
    }
    .index_list{
        margin-top: 32px;
    }
    .list_item{
        margin-bottom: 32px;
    }
    .item_title{
        margin-bottom: 16px;
        overflow: hidden;
        .spjz{
            font-size: 18px;
            font-weight: 700;
            color: var(--font-color);
            float: left;
        }
        .icon-huo{
            font-size: 24px;
            margin-right: 4px;
            color: var(--color);
        }
        .title_right{
            float: right;
            font-size: 14px;
            font-weight: 100;
            color: #999;
            cursor: pointer;
        }
    }
    .item_row{
        grid-template-columns: repeat(4,minmax(0,1fr));
        display: grid;
        gap: 16px;
        .row_{
            cursor: pointer;
            width: 303px;
            border-radius: 6px;
            overflow: hidden;
            background-color: #fff;
            box-sizing: border-box;
            img{
                width: 100%;
                height: 160px;
            }
            .row_text{
                padding: 0 16px;
                .row_title{
                    height: 50px;
                    font-size: 16px;
                    margin-top: 4px;
                }
                .row_info{
                    color: var(--font-color);
                    padding-top: 6px;
                }
                .row_money{
                    font-size: 20px;
                    color: #df0520;
                    padding-top: 20px;
                    padding-bottom: 8px;
                    span{
                        font-size: 12px;
                        color: #aaa;
                        margin-left: 2px;
                        text-decoration: line-through;
                    }
                }
            }
        }
        .row_:hover{
            .row_title{
                color: var(--color);
            }
        }
    }
    .index_head_class {
        width: 160px;
        height: 420px;
        background-color: #fff;
        border-radius: 8px;
        float: left;
        margin-right: 20px;
        overflow: hidden;
        ul {
            overflow-y: auto;
            height: 420px;
            margin-right: -17px;
            padding: 5px;
            li {
                padding:10px 0;
                text-align: center;
                cursor: pointer;
                border-radius: 9999px;
            }
            li:hover {
                --tw-bg-opacity: 1;
                color: var(--color);
                background-color: #f3f6ff;
            }
        }
    }
    .about_content {
        background-color: #fff;
        padding: 15px;
        margin-top: 40px;
        border-radius: 8px;
    }
    .about_title {
        font-size: 20px;
        font-weight: 600;
        margin-bottom: 5px;
    }
</style>
  