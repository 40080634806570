<template>
    <div>
        <div class="item_row">
            <div class="row_" v-for="(item,index) in list" @click="link_detail(item.id,item.type,item.vipSet)">
                <img :src="item.showVideoStatus!=3?(item.photoUrl || ''):item.memberPhotoReport.photoUrl" alt="" class="row_img">
                <div class="item_vip" v-if="item.vipSet==1&&!version">VIP</div>
                <div class="item_vip" v-else-if="item.vipSet==2&&!version">付费</div>
                <div class="item_vip" v-else>免费</div>
                <div class="item_date" v-if="item.showVideoStatus!=3&&!version&&item.viewType==1">{{item.videoLength ? changSecond(item.videoLength||'00:00') : '00:00'}}</div>
                <div class="row_text">
                    <div class="row_title caochu">
                        {{item.showVideoStatus!=3?(item.videoTitle || ''):item.memberPhotoReport.reportText}}
                    </div>
                    <div class="row_info" v-if="item.showVideoStatus==3">
                        {{(item.memberPhotoReport.praiseCount || 0)>10000?changeUnits(item.memberPhotoReport.praiseCount):(item.memberPhotoReport.praiseCount || 0)}} 学习
                    </div>
                    <div class="row_info" v-else>
                        {{(item.viewedNum || 0)>10000?changeUnits(item.viewedNum):(item.viewedNum || 0)}} 学习 · {{(item.praiseCount || 0)>10000?changeUnits(item.praiseCount):(item.praiseCount || 0)}} 喜欢
                    </div>
                    <div class="row_money" v-if="item.vipSet==2&&!version">
                        ¥{{item.price || 0}}
                        <!-- <span>¥488</span> -->
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    export default {
        props:{
            list:{
                type:Array,
                default:() => []
            }
        },
        data() {
            return {
                version:this.$store.state.version,
            }
        },
        methods:{
            changSecond(e) { //转换成秒
				return this.$secondToMinutes(e)
			},
            changeUnits(num) {
				let val = num/10000;
				val = val.toFixed(1);
				return val + '万'
			},
            link_detail(id,type,vipSet) {
                if(type==1){
                    this.$link('/video_detail?id=' + id + '&mode=video&timestamp=' + new Date())
				}else if(type==2){
					if(vipSet==2){
                        this.$link('/video_detail2?id=' + id + '&mode=video&timestamp=' + new Date())
					}else{
						this.$link('/video_detail1?id=' + id + '&mode=video&timestamp=' + new Date())
					}
				}
                
            }
        }
    }
</script>

<style lang="less" scoped>
    .item_row{
        grid-template-columns: repeat(4,minmax(0,1fr));
        display: grid;
        gap: 16px;
        .row_{
            cursor: pointer;
            width: 303px;
            border-radius: 6px;
            overflow: hidden;
            background-color: #fff;
            box-sizing: border-box;
            padding-bottom: 10px;
            position: relative;
            img{
                width: 100%;
                height: 160px;
            }
            .row_text{
                padding: 0 16px;
                .row_title{
                    height: 50px;
                    font-size: 16px;
                    margin-top: 4px;
                }
                .row_info{
                    color: var(--font-color);
                    padding-top: 6px;
                }
                .row_money{
                    font-size: 20px;
                    color: #df0520;
                    padding-top: 20px;
                    // padding-bottom: 8px;
                    span{
                        font-size: 12px;
                        color: #aaa;
                        margin-left: 2px;
                        text-decoration: line-through;
                    }
                }
            }
        }
        .row_:hover{
            .row_title{
                color: var(--color);
            }
        }
    }
    .item_vip{
        height: 20px;
        font-size: 14px;
        line-height: 20px;
        padding: 0 12px;
        border-radius: 3px;
        background-color: #f6bd74;
        color: #9c6a27;
        position: absolute;
        right: 5px;
        top: 5px;
    }
    .item_date{
        height: 15px;
        font-size: 12px;
        line-height: 15px;
        padding: 0 5px;
        border-radius: 7px;
        background-color: rgba(0, 0, 0, .5);
        color: #fff;
        position: absolute;
        right: 5px;
        bottom: 100px;
    }
</style>